import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import ModalImage from "react-modal-image";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import AddressSelectModal from "../../components/Modal/AddressSelectModal";
import { postImages } from "../../controller/imageController";
import { paymentHistoryController } from "../../controller/PaymentController";
import {
  acceptPosterController,
  createOrUpdatePosterController,
  deletePosterController,
  posterDetailController,
  updateArtistImageController,
} from "../../controller/PosterController";
import "../../styles/styles.css";

const photoNums = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const genreList = [
  {
    name: "EDM",
  },

  {
    name: "HOUSE",
  },
  {
    name: "DISCO",
  },
  {
    name: "TECHNO",
  },
  {
    name: "TRANCE",
  },
  {
    name: "D&B",
  },
  {
    name: "AFROBEAT",
  },
  {
    name: "DUBSTEP",
  },
  {
    name: "JAZZ",
  },
  {
    name: "POP",
  },
];
const moodList = [
  { name: "트렌디한" },
  { name: "힙한" },
  { name: "신나는" },
  { name: "감성적인" },
  { name: "그루브한" },
  { name: "어쿠스틱" },
  { name: "몽환적인" },
  { name: "강렬한" },
  { name: "잔잔한" },
  { name: "레트로" },
  { name: "감각적인" },
  { name: "소울풀한" },
  { name: "청량한" },
  { name: "케이팝" },
  { name: "산뜻한" },
  { name: "편안한" },
  { name: "웅장한" },
  { name: "어두운" },
];

const PosterDetail = () => {
  const queryClient = useQueryClient();

  const [posterImgUrls, setPosterImgUrls] = React.useState([]);
  const [institution, setInstitution] = React.useState("");

  const [eventName, setEventName] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [address, setAddress] = React.useState("");

  const [detailAddress, setDetailAddress] = React.useState("");
  const [latitude, setLatitude] = React.useState(0);
  const [longitude, setLongitude] = React.useState(0);

  const [tickets, setTickets] = React.useState([]);
  const [ticketName, setTicketName] = React.useState("");
  const [ticketPrice, setTicketPrice] = React.useState("");
  const [ticketCount, setTicketCount] = React.useState(0);
  const [ticketDescription, setTicketDescription] = React.useState("");

  const [description, setDescription] = React.useState("");
  const [moods, setMoods] = React.useState([]);
  const [genres, setGenres] = React.useState([]);
  const [homepageUrl, setHomepageUrl] = React.useState("");

  const [artistName, setArtistName] = React.useState("");
  const [artistImgUrl, setArtistImgUrl] = React.useState("");
  const [artistInfoUrl, setArtistInfoUrl] = React.useState("");

  const [manager, setManager] = React.useState("");
  const [managerContact, setManagerContact] = React.useState("");

  const [bankName, setBankName] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [accountHolder, setAccountHolder] = React.useState("");

  const [freeTicket, setFreeTicket] = React.useState(false);
  const [availableTicket, setAvailableTicket] = React.useState(false);
  const [isOnSitePurchase, setIsOnSitePurchase] = React.useState(false);

  // 주소찾기 모달 오픈
  const [addressSearchModalOn, setAddressSearchModalOn] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();

  const [simpleAddress, setSimpleAddress] = React.useState("");

  const [artistList, setArtistList] = React.useState([]);

  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");

  React.useEffect(() => {
    const controllerStart = async () => {
      if (id) {
        const response = await posterDetailController(id, status);

        console.log(response);

        setEventName(response.eventName || ""); // Default to empty string
        setDescription(response.description || "");
        setStartDate(response.startDate || "");
        setEndDate(response.endDate || "");

        if (response.moods !== null) {
          setMoods(response.moods.split(","));
        }
        if (response.genres !== null) {
          setGenres(response.genres.split(","));
        }
        setHomepageUrl(response.homepageUrl || "");
        setInstitution(response.institution || "");

        setManager(response.manager || "");
        setManagerContact(response.managerContact || "");

        setPosterImgUrls([...response.posterImages] || []);
        setTickets(response.tickets || []);

        setAddress(response.place.address || "");
        setDetailAddress(response.place.detailAddress || "");
        setSimpleAddress(response.place.simpleAddress || "");

        setLongitude(response.place.longitude || 0);
        setLatitude(response.place.latitude || 0);

        setArtistList(response.artists || []);

        setFreeTicket(response.isFree || false);
        setAvailableTicket(response.isBookingUnavailable || false);
        setIsOnSitePurchase(response.isOnSitePurchase || false);
      }
    };
    controllerStart();
  }, [id]);

  const { data: paymentData } = useQuery({
    queryKey: ["payment_history"],
    queryFn: () => {
      if (id !== null && id !== undefined) {
        return paymentHistoryController(id, 0, 300);
      } else {
        return null;
      }
    },
  });

  const acceptMutation = useMutation({
    mutationFn: () => acceptPosterController([id]),
    onSuccess: () => {
      navigate(-1);
    },
  });

  const rejectMutation = useMutation({
    mutationFn: () => acceptPosterController([id]),
    onSuccess: () => {
      navigate(-1);
    },
  });

  React.useEffect(() => {
    if (paymentData !== undefined && paymentData !== null) {
      setBankName(paymentData.account.bankName);
      setAccountNumber(paymentData.account.accountNumber);
      setAccountHolder(paymentData.account.accountHolder);
    }
  }, [paymentData]);
  React.useEffect(() => {
    setArtistImgUrl("");
    setArtistInfoUrl("");
    setArtistName("");
  }, [artistList]);

  return (
    <>
      <Container
        sx={{
          paddingBottom: 0,
          position: "relative",
        }}
      >
        <Header />
        <Box
          mb={5}
          mt={5}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" fontWeight={"700"}>
            포스터 상세보기
          </Typography>
          <Box display={"flex"} flexDirection={"row"} gap={1.5}>
            <Button
              variant="contained"
              onClick={async () => {
                let data = {
                  posterImgUrls: posterImgUrls,
                  institution: institution,
                  manager: manager,
                  managerContact: managerContact,
                  event: {
                    isFree: freeTicket,
                    isBookingUnavailable: availableTicket,
                    isOnSitePurchase: isOnSitePurchase,
                    eventName: eventName,
                    startDate: startDate,
                    endDate: endDate,
                    place: {
                      address: address,
                      simpleAddress: simpleAddress,
                      detailAddress: detailAddress,
                      latitude: latitude,
                      longitude: longitude,
                    },
                    tickets: tickets,
                    description: description,
                    moods:
                      moods.length === 1
                        ? moods[0]
                        : moods.length > 1
                        ? moods.join(",")
                        : null,
                    genres:
                      genres.length === 1
                        ? genres[0]
                        : genres.length > 1
                        ? genres.join(",")
                        : null,
                    homepageUrl: homepageUrl,
                    artists: artistList,
                    account: {
                      bankName: bankName,
                      accountNumber: accountNumber,
                      accountHolder: accountHolder,
                    },
                  },
                };
                if (id !== null && id !== undefined) {
                  const response = await createOrUpdatePosterController(
                    id,
                    data
                  );
                  if (response.code === 1000) {
                    alert("수정되었습니다.");
                    queryClient.invalidateQueries("poster_list");
                    navigate(-1);
                  } else {
                    alert("수정실패했습니다.");
                  }
                } else {
                  const response = await createOrUpdatePosterController(
                    undefined,
                    data
                  );
                  if (response.code === 1000) {
                    alert("등록되었습니다.");
                    queryClient.invalidateQueries("poster_list");
                    navigate(-1);
                  } else {
                    alert("등록에실패했습니다.");
                  }
                }
              }}
            >
              등록/수정 하기
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (status === "ACTIVE") {
                  rejectMutation.mutate(id);
                } else {
                  acceptMutation.mutate(id);
                }
              }}
            >
              {status === "ACTIVE" ? "반려하기" : "승인하기"}
            </Button>
            <Button
              variant="filled"
              onClick={() => {
                deletePosterController(id);
              }}
            >
              삭제하기
            </Button>
          </Box>
        </Box>
        <TextField
          variant="filled"
          sx={{
            width: "100%",
            marginBottom: 1.5,
          }}
          InputLabelProps={{}}
          value={eventName}
          label="제목을 입력하세요"
          onChange={(e) => setEventName(e.target.value)}
        />
        <TextField
          variant="filled"
          sx={{
            width: "100%",
            marginBottom: 1.5,
          }}
          multiline={true}
          rows={15}
          InputLabelProps={{}}
          value={description}
          label="내용을 입력하세요"
          onChange={(e) => setDescription(e.target.value)}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 2.5,
          }}
        >
          {photoNums.map((el, photoI) => (
            <Box
              mr={1.5}
              key={JSON.stringify(el) + photoI}
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                document.getElementById(`file-input-${photoI}`).click()
              }
            >
              {posterImgUrls[photoI] !== undefined ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ModalImage
                    className="modal-poster-style"
                    small={posterImgUrls[photoI]}
                    large={posterImgUrls[photoI]}
                    hideDownload={false}
                    hideZoom={false}
                  />
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      e.stopPropagation();
                      let temp = [...posterImgUrls];
                      temp.splice(photoI, 1);
                      setPosterImgUrls(temp);
                    }}
                  >
                    삭제
                  </Button>
                </Box>
              ) : (
                <Box
                  style={{
                    width: "150px",
                    height: "150px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#303030",
                    borderRadius: 75,
                  }}
                >
                  <Typography
                    variant="caption"
                    fontWeight={"700"}
                    color={"#fff"}
                  >
                    포스터 사진
                  </Typography>
                </Box>
              )}
              <input
                value={""}
                id={`file-input-${photoI}`} // Unique ID for each input
                type="file"
                style={{ display: "none" }} // Hide the input
                onChange={async (e) => {
                  if (e.target.files[0]) {
                    const file = e.target.files[0];
                    const formData = new FormData();
                    formData.append("file", file);
                    const response = await postImages(formData);
                    let temp = [...posterImgUrls];
                    if (response.code === 1900) {
                      temp.push(response.result.imageUrls[0]);
                    } else {
                      alert("업로드에 실패했습니다.");
                    }
                    setPosterImgUrls(temp);
                    if (file) {
                      console.log("Selected file:", file);
                      console.log(e.target.files);
                    }
                  }
                }}
              />
            </Box>
          ))}
        </Box>
        <Box
          style={{
            marginTop: 30,
            width: "70%",
            display: "flex",
            flexDirection: "row",
            gap: 4,
          }}
        >
          <TextField
            variant="filled"
            sx={{
              width: "100%",
              marginBottom: 1.5,
            }}
            InputLabelProps={{}}
            value={startDate}
            placeholder=""
            label="시작일자 '2024-05-20 18:30:00' 이렇게 입력해주세요"
            onChange={(e) => setStartDate(e.target.value)}
          />
          ~
          <TextField
            variant="filled"
            sx={{
              width: "100%",
              marginBottom: 1.5,
            }}
            InputLabelProps={{}}
            value={endDate}
            placeholder=""
            label="끝나는일자 '2024-05-20 18:30:00' 이렇게 입력해주세요"
            onChange={(e) => setEndDate(e.target.value)}
          />
        </Box>
        <Typography variant={"h6"}>장르</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 1.5,
            marginBottom: 10,
          }}
        >
          {genreList.map((el) => (
            <Box
              key={el.name}
              sx={{
                paddingLeft: 1,
                paddingRight: 1,
                paddingTop: 0.5,
                paddingBottom: 0.5,
                borderRadius: 1,
                border: "1px solid #303030",
                cursor: "pointer",
                backgroundColor: genres.includes(el.name) ? "#303030" : "#fff",
                color: genres.includes(el.name) ? "#fff" : "#303030",
              }}
              onClick={() => {
                setGenres((prevGenres) => {
                  if (prevGenres.includes(el.name)) {
                    // If the genre is already selected, remove it
                    return prevGenres.filter((genre) => genre !== el.name);
                  } else {
                    // Otherwise, add it to the array
                    return [...prevGenres, el.name];
                  }
                });
              }}
            >
              {el.name}
            </Box>
          ))}
        </Box>
        <Typography variant={"h6"}>무드</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 1.5,
            marginBottom: 3,
          }}
        >
          {moodList.map((el) => (
            <Box
              key={el.name}
              sx={{
                paddingLeft: 1,
                paddingRight: 1,
                paddingTop: 0.5,
                paddingBottom: 0.5,
                borderRadius: 1,
                border: "1px solid #303030",
                cursor: "pointer",
                backgroundColor: moods.includes(el.name) ? "#303030" : "#fff",
                color: moods.includes(el.name) ? "#fff" : "#303030",
              }}
              onClick={() => {
                // setMood(el.name);
                setMoods((prevMoods) => {
                  if (prevMoods.includes(el.name)) {
                    // If the mood is already selected, remove it
                    return prevMoods.filter((mood) => mood !== el.name);
                  } else {
                    // Otherwise, add it to the array
                    return [...prevMoods, el.name];
                  }
                });
              }}
            >
              {el.name}
            </Box>
          ))}
        </Box>

        <Typography variant={"h6"}>장소</Typography>
        <Button
          variant="contained"
          onClick={() => setAddressSearchModalOn((prev) => !prev)}
          sx={{ marginBottom: 4 }}
        >
          장소 검색
        </Button>
      </Container>
      {addressSearchModalOn && (
        <AddressSelectModal
          setIsOpen={setAddressSearchModalOn}
          address={address}
          setAddress={setAddress}
          setLatitude={setLatitude}
          setLongitude={setLongitude}
        />
      )}
      <Container sx={{ paddingBottom: 10 }}>
        <TextField
          variant="filled"
          sx={{
            width: "100%",
            marginBottom: 1.5,
          }}
          InputLabelProps={{}}
          value={address}
          placeholder=""
          label="주소"
          disabled={true}
          onChange={(e) => setDetailAddress(e.target.value)}
        />
        <TextField
          variant="filled"
          sx={{
            width: "100%",
            marginBottom: 1.5,
          }}
          InputLabelProps={{}}
          value={detailAddress}
          placeholder=""
          label="상세주소"
          onChange={(e) => setDetailAddress(e.target.value)}
        />
        <TextField
          variant="filled"
          sx={{
            width: "100%",
            marginBottom: 1.5,
          }}
          InputLabelProps={{}}
          value={simpleAddress}
          placeholder=""
          label="행정동 구분"
          onChange={(e) => setSimpleAddress(e.target.value)}
        />
        <TextField
          variant="filled"
          sx={{
            width: "100%",
            marginBottom: 1.5,
          }}
          InputLabelProps={{}}
          value={homepageUrl}
          placeholder=""
          label="홈페이지 URL"
          onChange={(e) => setHomepageUrl(e.target.value)}
        />
        <TextField
          variant="filled"
          sx={{
            width: "100%",
            marginBottom: 1.5,
          }}
          InputLabelProps={{}}
          value={bankName}
          placeholder=""
          label="은행"
          onChange={(e) => setBankName(e.target.value)}
        />
        <TextField
          variant="filled"
          sx={{
            width: "100%",
            marginBottom: 1.5,
          }}
          InputLabelProps={{}}
          value={accountNumber}
          placeholder=""
          label="계좌"
          onChange={(e) => setAccountNumber(e.target.value)}
        />
        <TextField
          variant="filled"
          sx={{
            width: "100%",
            marginBottom: 1.5,
          }}
          InputLabelProps={{}}
          value={accountHolder}
          placeholder=""
          label="예금주"
          onChange={(e) => setAccountHolder(e.target.value)}
        />
        <TextField
          variant="filled"
          sx={{
            width: "100%",
            marginBottom: 1.5,
          }}
          InputLabelProps={{}}
          value={manager}
          placeholder=""
          label="담당자"
          onChange={(e) => setManager(e.target.value)}
        />
        <TextField
          variant="filled"
          sx={{
            width: "100%",
            marginBottom: 1.5,
          }}
          InputLabelProps={{}}
          value={managerContact}
          placeholder=""
          label="담당자 연락처"
          onChange={(e) => setManagerContact(e.target.value)}
        />
        <Box
          sx={{
            padding: 4,
            border: "1px solid #303030",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <TextField
            variant="filled"
            label="티켓이름"
            onChange={(e) => setTicketName(e.target.value)}
            value={ticketName}
          />
          <TextField
            variant="filled"
            label="가격"
            onChange={(e) => setTicketPrice(e.target.value)}
            value={ticketPrice}
          />
          <TextField
            variant="filled"
            label="티켓개수"
            onChange={(e) => setTicketCount(e.target.value)}
            value={ticketCount}
          />
          <TextField
            variant="filled"
            label="티켓설명"
            onChange={(e) => setTicketDescription(e.target.value)}
            value={ticketDescription}
          />
          <Button
            variant="contained"
            onClick={() => {
              let temp = [...tickets];
              temp.push({
                ticketType: ticketName,
                ticketPrice: Number(ticketPrice),
                maxTicketCount: Number(ticketCount),
                ticketDescription: ticketDescription,
              });
              setTickets(temp);
              setTicketName("");
              setTicketPrice("");
              setTicketDescription("");
              setTicketCount(0);
            }}
          >
            추가
          </Button>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1.5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              if (availableTicket) {
                setAvailableTicket((prev) => !prev);
              }

              if (isOnSitePurchase) {
                setIsOnSitePurchase((prev) => !prev);
              }
              setFreeTicket((prev) => !prev);
            }}
          >
            <Box
              sx={{
                width: 10,
                height: 10,
                border: "1px  solid black",
                backgroundColor: freeTicket ? "blue" : "",
              }}
            ></Box>
            <Typography>무료공연</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              if (freeTicket) {
                setFreeTicket((prev) => !prev);
              }
              if (isOnSitePurchase) {
                setIsOnSitePurchase((prev) => !prev);
              }
              setAvailableTicket((prev) => !prev);
            }}
          >
            <Box
              sx={{
                width: 10,
                height: 10,
                border: "1px  solid black",
                backgroundColor: availableTicket ? "blue" : "",
              }}
            ></Box>
            <Typography>예매불가</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              if (freeTicket) {
                setFreeTicket((prev) => !prev);
              }
              if (availableTicket) {
                setAvailableTicket((prev) => !prev);
              }
              setIsOnSitePurchase((prev) => !prev);
            }}
          >
            <Box
              sx={{
                width: 10,
                height: 10,
                border: "1px  solid black",
                backgroundColor: isOnSitePurchase ? "blue" : "",
              }}
            ></Box>
            <Typography>FETE 예매불가</Typography>
          </Box>
        </Box>
        {tickets !== undefined &&
          tickets.length > 0 &&
          tickets.map((ticketEl, ticketIndex) => (
            <Box
              key={JSON.stringify(ticketEl) + ticketIndex}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography>
                이름: {ticketEl.ticketType} / 가격{ticketEl.ticketPrice}원 /
                매수{ticketEl.maxTicketCount}개 / 설명:{" "}
                {ticketEl.ticketDescription}
              </Typography>
              <Button
                onClick={() => {
                  let temp = [...tickets];
                  temp.splice(ticketIndex, 1);
                  setTickets(temp);
                }}
              >
                삭제
              </Button>
            </Box>
          ))}
        <Box sx={{ marginTop: 5, display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">아티스트</Typography>
            <Button
              variant="contained"
              onClick={() => {
                let temp = artistList.map((el) => {
                  return el.imageUrl;
                });
                console.log(temp);
                updateArtistImageController(id, temp);
              }}
            >
              아티스트 수정사항 저장
            </Button>
          </Box>
          <Box
            sx={{
              marginBottom: 4,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {artistImgUrl !== undefined && artistImgUrl.length > 0 ? (
              <ModalImage
                className="modal-artist-style"
                small={artistImgUrl}
                large={artistImgUrl}
                hideDownload={false}
                hideZoom={false}
              />
            ) : (
              <Box
                sx={{
                  width: 120,
                  height: 120,
                  borderRadius: 3,
                  backgroundColor: "#303030",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  document.getElementById("artist-file-input").click();
                }}
              >
                <Typography variant={"body1"} color={"#fff"}>
                  선택
                </Typography>
              </Box>
            )}
            <TextField
              variant="filled"
              label="이름"
              onChange={(e) => setArtistName(e.target.value)}
              value={artistName}
            />
            <TextField
              variant="filled"
              label="아티스트 정보url"
              onChange={(e) => setArtistInfoUrl(e.target.value)}
              value={artistInfoUrl}
            />
            <Button
              variant="contained"
              onClick={() => {
                let temp = [...artistList];
                temp.push({
                  name: artistName,
                  infoUrl: artistInfoUrl,
                  imageUrl: artistImgUrl,
                });
                setArtistList(temp);
              }}
            >
              추가
            </Button>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            {artistList &&
              artistList.length > 0 &&
              artistList.map((artistEl, artistIndex) => {
                return (
                  <Box
                    key={JSON.stringify(artistEl) + artistIndex}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      document
                        .getElementById(`artist-file-input-${artistIndex}`)
                        .click()
                    }
                  >
                    <Box
                      mr={1.5}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {artistEl.imageUrl !== "" ? (
                        <ModalImage
                          className="modal-artist-style"
                          small={artistEl.imageUrl}
                          large={artistEl.imageUrl}
                          hideDownload={false}
                          hideZoom={false}
                        />
                      ) : (
                        <Box></Box>
                      )}
                    </Box>
                    <input
                      value={""}
                      id={`artist-file-input-${artistIndex}`} // Unique ID for each input
                      type="file"
                      style={{ display: "none" }} // Hide the input
                      onChange={async (e) => {
                        if (e.target.files[0]) {
                          const file = e.target.files[0];
                          const formData = new FormData();
                          formData.append("file", file);
                          const response = await postImages(formData);
                          if (response.code === 1900) {
                            let temp = [...artistList];
                            temp[artistIndex].imageUrl =
                              response.result.imageUrls[0];
                            setArtistList([...temp]);
                          } else {
                            alert("업로드에 실패했습니다.");
                          }
                        }
                      }}
                    />
                    <Typography variant={"body2"}>
                      이름: {artistEl.name}
                    </Typography>
                    <Typography variant={"body2"}>
                      정보url: {artistEl.infoUrl}
                    </Typography>
                    <Button
                      onClick={() => {
                        let temp = [...artistList];
                        temp.splice(artistIndex, 1);
                        setArtistList(temp);
                      }}
                    >
                      삭제
                    </Button>
                  </Box>
                );
              })}
          </Box>
        </Box>
        <input
          value={""}
          id={"artist-file-input"} // Unique ID for each input
          type="file"
          style={{ display: "none" }} // Hide the input
          onChange={async (e) => {
            if (e.target.files[0]) {
              const file = e.target.files[0];
              const formData = new FormData();
              formData.append("file", file);
              const response = await postImages(formData);
              if (response.code === 1900) {
                setArtistImgUrl(response.result.imageUrls[0]);
              } else {
                alert("업로드에 실패했습니다.");
              }
            }
          }}
        />

        {paymentData !== undefined && paymentData !== null && (
          <>
            <Typography variant={"h6"} sx={{ marginTop: 4, marginBottom: 4 }}>
              총액: {paymentData.totalProfit}
            </Typography>
            <Grid
              container
              sx={{ paddingBottom: 1, borderBottom: 1, borderColor: "silver" }}
            >
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Typography variant="body1" fontWeight={"600"}>
                  거래자명
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Typography variant="body1" fontWeight={"600"}>
                  티켓종류
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}></Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Typography variant="body1" fontWeight={"600"}>
                  총액
                </Typography>
              </Grid>
            </Grid>
            {paymentData.payments !== undefined &&
              paymentData.payments !== null &&
              paymentData.payments.map((paymentEl, paymentIndex) => {
                if (paymentEl.isPaid === true) {
                  return (
                    <Grid
                      container
                      sx={{
                        paddingBottom: 0.8,
                        paddingTop: 0.8,
                        borderBottom: 1,
                        borderColor: "silver",
                        "&:hover": {
                          backgroundColor: "#eee",
                        },
                      }}
                      key={JSON.stringify(paymentEl) + paymentIndex}
                    >
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {paymentEl.member.userName}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {paymentEl.ticketType}
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}></Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        {paymentEl.totalAmount}
                      </Grid>
                    </Grid>
                  );
                } else {
                  return (
                    <Box key={JSON.stringify(paymentEl) + paymentIndex}></Box>
                  );
                }
              })}
          </>
        )}
      </Container>
    </>
  );
};

export default PosterDetail;
